import { BrowserRouter} from "react-router-dom"
import Animatedroute from "./components/animatedroute/AnimatedRoute";

const App = () => {

  return (
    
    <>
   
      <BrowserRouter>
      
        <Animatedroute />

    </BrowserRouter>
    </>
  )
}

export default App
